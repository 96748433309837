// src/data/businesses.js
const businesses = [
    {
      name: "Business 1",
      logo: "path_to_logo1.jpg",
      link: "https://business1.com",
      description: "Leading provider of innovative solutions in technology.",
    },
    {
      name: "Business 2",
      logo: "path_to_logo2.jpg",
      link: "https://business2.com",
      description: "Experts in digital marketing and branding.",
    },
    {
      name: "Business 3",
      logo: "path_to_logo3.jpg",
      link: "https://business3.com",
      description: "Specializing in environmentally sustainable practices.",
    },
    {
      name: "Business 4",
      logo: "path_to_logo4.jpg",
      link: "https://business4.com",
      description: "Industry leaders in product development and design.",
    },
  ];
  
  export default businesses;
  