const teamMembers = [
    {
      name: "John Doe",
      role: "CEO",
      email: "john.doe@example.com",
      profileImage: "path_to_team_member1.jpg",
      linkedin: "https://www.linkedin.com/in/johndoe"
    },
    {
      name: "Jane Smith",
      role: "CTO",
      email: "jane.smith@example.com",
      profileImage: "path_to_team_member2.jpg",
      linkedin: "https://www.linkedin.com/in/janesmith"
    },
    {
      name: "Emily Johnson",
      role: "CFO",
      email: "emily.johnson@example.com",
      profileImage: "path_to_team_member3.jpg",
      linkedin: "https://www.linkedin.com/in/emilyjohnson"
    },
    {
      name: "Michael Brown",
      role: "COO",
      email: "michael.brown@example.com",
      profileImage: "path_to_team_member4.jpg",
      linkedin: "https://www.linkedin.com/in/michaelbrown"
    },
    // Add more team members as needed
  ];

  export default teamMembers